import request from '@/utils/request'
// import store from '@/store'

// 获取常量学校代码|数据来源
// const dataSource = store.state.settings.dataSource
// const schoolCode = store.state.settings.schoolCode
// const proModelUrl = 'transfer'

// 查询
export function getRefundApplicationList() {
  return request({
    url: 'pay/web/transfer/getTransferlListByStudent',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 新增保存
export function savePayTypeInfo(schoolCode, dataSource, accountType, accountName, accountNo) {
  return request({
    url: 'common/com/transfer',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'schoolCode': schoolCode,
      'dataSource': dataSource,
      'accountType': accountType,
      'accountName': accountName,
      'accountNo': accountNo
    }
  })
}

// 编辑保存
export function updatePayTypeInfo(schoolCode, dataSource, accountType, accountName, accountNo, id) {
  return request({
    url: 'common/com/transfer',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'schoolCode': schoolCode,
      'dataSource': dataSource,
      'accountType': accountType,
      'accountName': accountName,
      'accountNo': accountNo,
      'id': id
    }
  })
}

// 删除
export function personCenterAccountDelelte(id) {
  return request({
    url: 'common/com/transfer/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'id': id
    }
  })
}
// 退费申请
export function applyTransferBatch(data) {
  return request({
    url: 'pay/web/transfer/applyTransferBatch',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
// export function getProjectType(id) {
//   return request({
//     url: 'pay/pay/getProjectType/' + id,
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }

// export function deleteProjectTypeInfo(id) {
//   return request({
//     url: 'pay/pay/projectType/deleteProjectType/' + id,
//     method: 'delete',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }
// export function getProjectTypeAndProjectInfoListInit(schoolCode) {
//   return request({
//     url: 'pay/pay/cos/merchant/getProjectTypeAndProjectInfoListInit/' + schoolCode,
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }
// export function getProjectTypeAndProjectInfoList(parentId) {
//   return request({
//     url: 'pay/pay/cos/merchant/getProjectTypeAndProjectInfoList/' + parentId,
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }

